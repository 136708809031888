import React from 'react';
import { connect } from 'react-redux'
import server from '../api/server'

import Nav from './Nav'
import { Helmet } from "react-helmet";
import { fetchForm, fetchRequestedInfo } from '../actions'

import {Link} from "react-router-dom"

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.fetchForm(this.props.match.params.id)

    this.props.fetchRequestedInfo(this.props.match.params.id)
    this.fetchName()
  }

  constructor(props) {
    super(props)
    this.state = {
      name: ""
    }
  }

  componentDidUpdate = () => {
  }

  fetchName = async () => {
    const response = await server.post("/name", { id: this.props.match.params.id });
    if (response.data != false) {
      this.setState({ name: response.data })
    } else {
      this.setState({ name: " " })
    }
  }

  renderDashboard = () => {
    if (this.props.id == 'no-form') {
      return ('No-form')
    } else if (this.props.id != null && this.state.name != "") {
      return (
        <div>
          <Nav></Nav>
          <div className="ui container">
            <div className="main-wrap">
              <div className='file-upload-row'>
              {
                this.props.requested_info.fact_find == true ?
                  <div className="dashboard-module">
                    <h2>Fact Find Form</h2>

                    <p style={{ "marginTop": "7px", marginBottom: "14px" }}>Please complete this Fact Find Form so we can gain a better understanding of your circumstances. If you're looking at completing this on a mobile device, we recommend you complete this in a landscape orientation.</p>
                    <Link className="ui button dashboard-module-button" to={`/factfindform/${this.props.id}`}>Start Fact Find Form</Link>
                  </div> : null
              }

              {this.props.requested_info.id == true ?
                <div className="dashboard-module">
                  <h2>Verification of Identity</h2>
                  <p style={{ "marginTop": "7px", marginBottom: "14px" }}>Please complete the verification of identity process so we can process your application. This process should take around 5 minutes.</p>


                  <Link className="ui button dashboard-module-button" to={`/identification/${this.props.id}`}>Start Verification of Identity</Link>
                </div> : null}

                {this.props.requested_info.docs == true ?                 
                <div className="dashboard-module">
                  <h2>File Upload</h2>
                  <p style={{ "marginTop": "7px", marginBottom: "14px" }}>Please upload required files so we can process your application. This process should take around 5 minutes.</p>


                  <Link className="ui button dashboard-module-button" to={`/upload/${this.props.id}`}>Start File Upload</Link>
                </div>:null}

                {this.props.requested_info.bankstatements == true ?                 
                <div className="dashboard-module">
                  <h2>Bank Statements</h2>
                  <p style={{ "marginTop": "7px", marginBottom: "14px" }}>Please upload your bank statements via our secure portal. This process should take around 2 minutes.</p>


                  <Link className="ui button dashboard-module-button" to={`/bankstatements/${this.props.id}`}>Start Bank Statement Collection</Link>
                </div>:null}
              </div>


                


            </div>



          </div>
        </div>
      )
    } else {
      return (<div className="form-loading">
        <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" />
      </div>)
    }
  }

  render() {
    return (
      <div className="dashboard">
        <Helmet>
          <title>Loan Gateway - Mortgage Choice Sydney CBD</title>

          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
          <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />
        </Helmet>
        {this.renderDashboard()}
        {/* <div style={{ opacity: "0.7", textAlign: "center", padding: "15px", position: "absolute", bottom: "0", width: "100%" }}>
          <span>
            Copyright 2022 CBD Mortgage Advisors Pty Ltd | ABN 87 636 147 496 | Suite 2, Level 2, 88 Pitt Street SYDNEY NSW 2000
                    </span>
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { id: state.id, requested_info: state.requested_info }
}

export default connect(mapStateToProps, { fetchForm, fetchRequestedInfo })(Dashboard)
