import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip,faClose, faCheckCircle, faTimesCircle, faQuestionCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'
import server from '../../api/server'

import { updateUploadedFiles } from '../../actions'
export class FileUploader extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
        uploading:false
      }
    }


uploadImage = async (files) =>{
    this.setState({uploading:true})
    const formData = new FormData();

    
    console.log(files)
    files.forEach(file => {
        formData.append('files', file)
    })
    // formData.append(
    //     'file',
    //     files[0]
    // )

    formData.append(
        "label",
        this.props.data.label
    )

    formData.append(
        "file_id",
        this.props.data.id
    )

    formData.append(
        "applicant_id",
        this.props.applicant_id
    )

    formData.append(
        "upload_id",
        this.props.document_collection.data.id
    )
    console.log(formData)

    const response = await server.post("/upload",formData).catch(e =>{})
    if(response.data && response.data.success == true){
        this.props.updateUploadedFiles(response.data.fileupload);

    }
    this.setState({uploading:false})

}

renderFiles = () =>{
    var files = []

    this.props.data.files.forEach((file,index) =>{
        files.push(
            <div style={{display:"flex",width:"100%"}}>
                    <div className='file-list-name'>
                        <span>{file.original_name}</span>
                    </div>
                    <div className='file-list-details'>
                        <div style={{opacity:"60%"}}>{(file.size/1024).toFixed(2)} KB</div>
                        <div className="file-list-delete" style={{marginLeft:"auto"}} onClick={() =>{
                            this.deleteFile(file.path,index)
                        }}><FontAwesomeIcon icon={faClose}/></div>
                    </div>
                </div>
        )
    })

    return files;
}


deleteFile = async (path,index) =>{
    var document_collection = {...this.props.document_collection.data}
    document_collection.applicants[this.props.applicant_id].requested_documents[this.props.data.id].files.splice(index,1)
    this.props.updateUploadedFiles(document_collection)
    var response = await server.post("/documentCollection/delete",{
        id:this.props.document_collection.data.id,
        path:path,
        index:index,
        file_id:this.props.data.id,
        applicant_id:this.props.applicant_id
    })

}

    renderTick = () =>{
        if(this.props.document_collection.data.applicants[this.props.applicant_id].requested_documents[this.props.data.id].min_documents == 0){

        }
        else if(this.props.data.files.length >= this.props.document_collection.data.applicants[this.props.applicant_id].requested_documents[this.props.data.id].min_documents){
            return (<FontAwesomeIcon icon={faCheckCircle} size={"2x"} color={"green"}/>)
        }
        else{
            return (<FontAwesomeIcon icon={faExclamationCircle} size={"2x"} color={"red"}/>)
        }
    }

    renderURL = () =>{
        var URL = 'https://www.bankstatements.com.au/iframe/start/MTGC-PAAS'
        if(this.props.bankstatements.data != undefined){
            var bankstatements = this.props.bankstatements.data;
            if(bankstatements.applicants[this.props.applicant_id] != undefined){
                 URL = `https://www.bankstatements.com.au/r/b/FTVH-${bankstatements.applicants[this.props.applicant_id].code}`
            }
        }
        return URL;
    }
  render() {
    return (
        <div className="dashboard-module file-upload-item">
            <div className='dashboard-module-tick'>
                    {this.renderTick()}
            </div>


        <div>
            <h2>{this.props.data.label}</h2>

            {
                this.props.data.label == "Bank Statement Collection" ?
                <div>
                    <p style={{ "marginTop": "7px", marginBottom: "14px" }}>It would be preferred that you use this link to provide your statements to us securely and efficiently. This web link has been developed in conjunction with the banks purely for loan applications.</p>
                    <p style={{ "marginTop": "7px", marginBottom: "14px" }}>Please upload the following accounts that apply to you:</p>

                    <ol style={{color:"#4c5055", fontSize:"400"}}>
                        <li>Salary Credit Accounts</li>
                        <li>Savings Accounts</li>
                        <li>Everyday Expense Accounts</li>
                        <li>Credit Card Facilities</li>
                        <li>Personal Loan Facilties</li>
                        <li>Home/Investment Loan Facilties</li>
                    </ol>
                    <p style={{ "marginTop": "7px", marginBottom: "14px" }}>Should you have accounts with mutiple institutions, please simply click this link for each institution.</p>

                </div>
                :            <p style={{ "marginTop": "7px", marginBottom: "14px" }}>{this.props.data.description} </p>

            }

            {/* <span>Upload</span> */}
            {
                this.props.data.label == "Bank Statement Collection" ?
                <div>
                    <a href={this.renderURL()} className='ui button dashboard-module-button' style={{width:"100%"}} target="_blank">Collect Bank Statements</a>
                </div>
                :
                <Dropzone onDrop={acceptedFiles => this.uploadImage(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()}  className='upload-box'>
                        <input {...getInputProps()}/>
                        <div>
                            <span><FontAwesomeIcon icon={faPaperclip}/> Add file or drop files here</span>
                        </div>
                    </div>
                )}
            </Dropzone>
            }



            <div className='file-list'>
                {this.renderFiles()}
                {this.state.uploading == true ? <div class="ui mini active inline loader"></div>:null}

            </div>
        </div>

    </div>
    )
  }
}

const mapStateToProps = (state) => ({
    document_collection:state.document_collection,
    bankstatements:state.bankstatements
})

const mapDispatchToProps = {
    updateUploadedFiles
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader)