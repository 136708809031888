import React, { Component } from 'react'
import { connect } from 'react-redux'

import { refreshVOIData, setVOIData } from "../../actions"
import { Base64 } from 'js-base64';

import server from "../../api/server"
import PDFJS from "pdfjs"
import VOIData  from './VOIData';
export class VOI extends Component {

	constructor(props) {
		super(props)

		this.state = {
			menu: "overview",
			url: "",
			pdfDisabled: false,
			applicant: null,
			requestDisable:false
		}
	}

	componentDidMount() {
		if (this.props.VOI != undefined) {
			// this.setState({applicant:this.props.applicant})
			// this.props.refreshVOIData(this.props.VOI.transaction_id)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		console.log("voi updated")
	}



	requestVOI = async () => {
		this.setState({requestDisable:true})
		const response = await server.post("/requestvoi", {id:this.props.user.token, application_id: this.props.id });
		this.props.setVOIData(response.data);
		this.setState({requestDisable:false})
	}









	renderApplicants = () => {
		const applicants = []
		Object.keys(this.props.VOI.applicants).forEach(key => {
			if(this.props.VOI.applicants[key].completed == true){
				applicants.push(
					<div className="col-lg-12">
						<VOIData applicant_id = {key}/>
					</div>
				)
			}else{
				applicants.push(
				<div className="col-lg-12">
					<div className="card gutter-b">
						<div className="card-header">
							<h3 className="card-label font-weight-bolder text-dark">Verification of Identity in Progress</h3>
							<span class="text-muted font-weight-bold font-size-sm mt-1">Verification of Identity</span>


						</div>

						<div className="card-body">
							<p>Your borrowers are yet to finalise the verification of identity  process. Once completed, there identification process, and report will be available here.</p>
						</div>
					</div>
				</div>
				)
			}

		})

		return applicants;
	}
	

	renderContent = () => {
		if (this.props.VOI != null) {
				return (
					<div className="row justify-content-center">
						{this.renderApplicants()}
					</div>
				)

		} else {
			return (
				<div className="row ">
					<div className="col-lg-12">
						<div className="card gutter-b">
							<div className="card-header">
								<h3 className="card-label font-weight-bolder text-dark">Request Verification of Identity</h3>
								<span class="text-muted font-weight-bold font-size-sm mt-1">Verification of Identity</span>


							</div>

							<div className="card-body">
								<p>Click the below link to commence the OCR Labs Verification of Identify process with your clients. Once you click the below link, the verification of identity box will appear on there client link. Each borrower will need to complete a separate report.</p>
								<button disabled={this.state.requestDisable} className={`btn  btn-info ${this.state.requestDisable == true ? "spinner spinner-white spinner-right" : null}`}  onClick={(e) => { this.requestVOI() }}>Request Verification of Identity</button>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}

	render() {
		return (
			<div>
				<div className="flex-row-fluid">
					{this.renderContent()}
				</div>


			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	VOI: state.admin.active_application.VOI,
	requested: state.admin.active_application.application.requested_info,
	id: state.admin.active_application.application.id,
	user:state.user
})

const mapDispatchToProps = {
	refreshVOIData,
	setVOIData
}

export default connect(mapStateToProps, mapDispatchToProps)(VOI)
