import React from 'react';
import {connect} from 'react-redux'
import {updateData} from '../../actions'
import DatePicker from './form_elements/DatePicker.js'

class General_Details extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      timeoutID:null,
      applicant1:{
        dob:{
          day:"",
          month:"",
          year:""
        },
        licence_expiry:{
          day:"",
          month:"",
          year:""
        }
      },
      applicant2:{
        dob:{
          day:"",
          month:"",
          year:""
        },
        licence_expiry:{
          day:"",
          month:"",
          year:""
        }
      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    if('General_Details' in this.props.data){
      console.log(this.props.data)
      const property = {...this.state};
      Object.keys(this.props.data['General_Details']).map(key =>{
        property[key] = this.props.data['General_Details'][key];
      })
      this.setState(property)
      console.log("STATE",property)
    }
  }

    componentDidUpdate(prevProps,prevState){
      clearTimeout(prevState.timeoutID)
    }

    formChange = (e) =>{
      const update = async () =>{
        console.log('Updating..')
        this.props.updateData({state:this.state,type:"General_Details"})
      }
      const timeoutID = setTimeout(function() {
        update()
      },250)
      this.setState({timeoutID:timeoutID})
    }

    handleInputChange = (e,applicant,field) =>{
      if(applicant == 1){
        const property = {...this.state.applicant1};
        property[field] = e.target.value
        this.setState({applicant1:property})
      }else if(applicant == 2){
        const property = {...this.state.applicant2};
        property[field] = e.target.value
        this.setState({applicant2:property})
      }
      console.log(this.state)
    }

    handleSubmit = (e) =>{
      e.preventDefault()
      this.props.updateData({state:this.state,type:"General_Details"})
      this.props.changeForm("Address_3y")
    }


    renderDependentChildrenInputs = () =>{
      var applicant1 = []
      var applicant2 = []
      var boxes = []
      if(this.state.applicant1.dependant_children && this.state.applicant1.dependant_children >0){
        for (let index = 0; index < this.state.applicant1.dependant_children; index++) {
          const variable_name = `dependant_child_age_${index+1}`
          applicant1.push(
            <td><input required type="text" value={this.state.applicant1[variable_name] || ''} onChange={(e) =>this.handleInputChange(e,'1',variable_name)}  name="" defaultValue="" /></td>
          )
          
        }
      }

      if(this.state.applicant2.dependant_children && this.state.applicant2.dependant_children >0){
        for (let index = 0; index < this.state.applicant2.dependant_children; index++) {
          const variable_name = `dependant_child_age_${index+1}`
          applicant2.push(
            <td><input required type="text" value={this.state.applicant2[variable_name] || ''} onChange={(e) =>this.handleInputChange(e,'2',variable_name)}  name="" defaultValue="" /></td>

          )
          
        }
      }
      if(applicant1.length > applicant2.length){
        for (let index = 0; index < applicant1.length; index++) {
          boxes.push(
            <tr>
              <td>Age of Dependent Child {`${index+1}`}</td>
              {applicant1[index]}
              {applicant2[index] != null ? applicant2[index]: <td></td>}
            </tr>
          )
          
        }
      }else{
        for (let index = 0; index < applicant2.length; index++) {
          boxes.push(
            <tr>
              <td>Age of Dependent Child {`${index+1}`}</td>
              {applicant1[index] != null ? applicant1[index]: <td></td>}
              {applicant2[index]}
            </tr>
          )
          
        }
      }
      return boxes
    }

    renderButtons = () =>{
      if(this.props.showButtons === 'false'){
        return(null)
      }else{
        return(
        <div className="">
          <button className="ui button primary " type="submit" style={{float:"right"}}>Next Page</button>
        </div>
        )
      }
    }

    dateChange = (value,applicant_number,type,field) =>{
        var applicant = `applicant${applicant_number}`
        const property = {...this.state[applicant]};
        if(type != null){
          property[field][type] = value;
        }else{
          property[field] = value;
        }
        this.setState({[applicant]:property})
        console.log(this.state)
        console.log(value,applicant_number);
      
    }


    render(){
        return(
        <div>
          <form  id="general_details_form" onSubmit = {(e) =>{this.handleSubmit(e)}} onChange = {this.formChange}>
          <table class="table">
          <thead>
            <tr>
              <th colspan="3">Your general details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td><b>Applicant 1</b></td>
              <td><b>Applicant 2</b></td>
            </tr>
            <tr>
              <td>Title</td>
              <td>
                <div>
                  <select tabindex="1" required defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant1.title || ''} onChange={(e) =>this.handleInputChange(e,"1","title")}>
                    <option defaultValue = ""></option>
                    <option defaultValue = "Mr">Mr</option>
                    <option defaultValue = "Mrs">Mrs</option>
                    <option defaultValue = "Ms">Ms</option>
                    <option defaultValue = "Dr">Dr</option>
                    <option defaultValue = "Other">Other</option>
                  </select>
                </div>
              </td>
              <td>
                <select defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant2.title || ''} onChange={(e) =>this.handleInputChange(e,"2","title")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Mr">Mr</option>
                  <option defaultValue = "Mrs">Mrs</option>
                  <option defaultValue = "Ms">Ms</option>
                  <option defaultValue = "Dr">Dr</option>
                  <option defaultValue = "Other">Other</option>
                </select>  
              </td>
            </tr>

            <tr>
              <td>Given Name</td>
              <td><input tabindex="2"  required type="text" value={this.state.applicant1.given_name || ''} onChange={(e) =>this.handleInputChange(e,'1','given_name')} name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.given_name || ''} onChange={(e) =>this.handleInputChange(e,'2','given_name')}  name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Middle Name(s)</td>
              <td><input tabindex="3"  type="text" value={this.state.applicant1.middle_name || ''} onChange={(e) =>this.handleInputChange(e,'1','middle_name')} name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.middle_name || ''} onChange={(e) =>this.handleInputChange(e,'2','middle_name')}  name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Family Name</td>
              <td><input tabindex="4"  required type="text" value={this.state.applicant1.family_name || ''} onChange={(e) =>this.handleInputChange(e,'1','family_name')} name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.family_name || ''} onChange={(e) =>this.handleInputChange(e,'2','family_name')}  name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Home Phone <small>(preferred contact no.)</small></td>
              <td><input tabindex="5"   type="text" value={this.state.applicant1.home_phone || ''} onChange={(e) =>this.handleInputChange(e,'1','home_phone')}  name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.home_phone || ''} onChange={(e) =>this.handleInputChange(e,'2','home_phone')} name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Mobile Phone</td>
              <td><input tabindex="6"  required type="text" value={this.state.applicant1.mobile || ''} onChange={(e) =>this.handleInputChange(e,'1','mobile')}  name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.mobile || ''} onChange={(e) =>this.handleInputChange(e,'2','mobile')} name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Work Phone</td>
              <td><input tabindex="7"  type="text" value={this.state.applicant1.work_phone || ''} onChange={(e) =>this.handleInputChange(e,'1','work_phone')}  name=""  defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.work_phone || ''} onChange={(e) =>this.handleInputChange(e,'2','work_phone')} name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Email <small>(preferred email for updates)</small></td>
              <td><input tabindex="8"  required type="text" value={this.state.applicant1.email || ''} onChange={(e) =>this.handleInputChange(e,'1','email')}  name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.email || ''} onChange={(e) =>this.handleInputChange(e,'2','email')} name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Date of birth</td>
              <td>
                {
                  "birth_day" in this.state.applicant1 && !("dob" in this.state.applicant1) && this.props.showButtons == "false" ?
                  <div><input required class="w20" type="text"  value={this.state.applicant1.birth_day || ''} onChange={(e) =>this.handleInputChange(e,'1','birth_day')} name="" defaultValue="" /> / <input required class="w20" type="text" value={this.state.applicant1.birth_month || ''} onChange={(e) =>this.handleInputChange(e,'1','birth_month')} name="" defaultValue="" /> / <input required class="w20" type="text" value={this.state.applicant1.birth_year || ''} onChange={(e) =>this.handleInputChange(e,'1','birth_year')} name="" defaultValue="" /></div>
                  :
                  <DatePicker  field="dob" required={true} dateChange={this.dateChange} applicant_number={1} value = {this.state.applicant1.dob}></DatePicker>

                }
              </td>
              {/* <td><input required class="w20" type="text"  value={this.state.applicant1.birth_day || ''} onChange={(e) =>this.handleInputChange(e,'1','birth_day')} name="" defaultValue="" /> / <input required class="w20" type="text" value={this.state.applicant1.birth_month || ''} onChange={(e) =>this.handleInputChange(e,'1','birth_month')} name="" defaultValue="" /> / <input required class="w20" type="text" value={this.state.applicant1.birth_year || ''} onChange={(e) =>this.handleInputChange(e,'1','birth_year')} name="" defaultValue="" /></td> */}
              {/* <td><input class="w20" type="text" value={this.state.applicant2.birth_day || ''} onChange={(e) =>this.handleInputChange(e,'2','birth_day')} name="" defaultValue="" /> / <input class="w20" type="text" value={this.state.applicant2.birth_month || ''} onChange={(e) =>this.handleInputChange(e,'2','birth_month')} name="" defaultValue="" /> / <input class="w20" type="text" value={this.state.applicant2.birth_year || ''} onChange={(e) =>this.handleInputChange(e,'2','birth_year')} name="" defaultValue="" /></td> */}
              <td>                
                {
                    "birth_day" in this.state.applicant2 && !("dob" in this.state.applicant2) && this.props.showButtons == "false" ?
                    <div><input class="w20" type="text" value={this.state.applicant2.birth_day || ''} onChange={(e) =>this.handleInputChange(e,'2','birth_day')} name="" defaultValue="" /> / <input class="w20" type="text" value={this.state.applicant2.birth_month || ''} onChange={(e) =>this.handleInputChange(e,'2','birth_month')} name="" defaultValue="" /> / <input class="w20" type="text" value={this.state.applicant2.birth_year || ''} onChange={(e) =>this.handleInputChange(e,'2','birth_year')} name="" defaultValue="" /></div>
                    :
                    <DatePicker field="dob" required={false} dateChange={this.dateChange} applicant_number={2} value = {this.state.applicant2.dob}></DatePicker>

                  }
              </td>

            
            </tr>

            <tr>
              <td>Gender</td>
              <td>
                <select defaultValue="" required className="ui dropdown form-dropdown" value = {this.state.applicant1.gender || ''} onChange={(e) =>this.handleInputChange(e,"1","gender")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Male">Male</option>
                  <option defaultValue = "Female">Female</option>
                  <option defaultValue = "Other">Other</option>
                </select> 
              </td>
              <td>
              <select defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant2.gender || ''} onChange={(e) =>this.handleInputChange(e,"2","gender")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Male">Male</option>
                  <option defaultValue = "Female">Female</option>
                  <option defaultValue = "Other">Other</option>
                </select> 
              </td>
            </tr>

            <tr>
              <td>Drivers Licence No.</td>
              <td><input tabindex="9"  type="text" value={this.state.applicant1.drivers_licence_no || ''} onChange={(e) =>this.handleInputChange(e,'1','drivers_licence_no')}  name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.drivers_licence_no || ''} onChange={(e) =>this.handleInputChange(e,'2','drivers_licence_no')} name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Drivers Licence State issued</td>
              <td>
                <select required className="ui dropdown form-dropdown" value = {this.state.applicant1.drivers_licence_state || ''} onChange={(e) =>this.handleInputChange(e,'1','drivers_licence_state')}>
                <option defaultValue = ""></option>
                  <option defaultValue = "NSW">NSW</option>
                  <option defaultValue = "QLD">QLD</option>
                  <option defaultValue = "SA">SA</option>
                  <option defaultValue = "VIC">VIC</option>
                  <option defaultValue = "WA">WA</option>
                  <option defaultValue = "ACT">ACT</option>
                  <option defaultValue = "NT">NT</option>
                </select>
              </td>

              <td>
                <select className="ui dropdown form-dropdown" value = {this.state.applicant2.drivers_licence_state || ''} onChange={(e) =>this.handleInputChange(e,'2','drivers_licence_state')}>
                <option defaultValue = ""></option>
                  <option defaultValue = "NSW">NSW</option>
                  <option defaultValue = "QLD">QLD</option>
                  <option defaultValue = "SA">SA</option>
                  <option defaultValue = "VIC">VIC</option>
                  <option defaultValue = "WA">WA</option>
                  <option defaultValue = "ACT">ACT</option>
                  <option defaultValue = "NT">NT</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>Drivers Licence Expiry</td>

              <td>
              {
                  "drivers_licence_expiry_day" in this.state.applicant1 && !("licence_expiry" in this.state.applicant1) && this.props.showButtons == "false" ?
                  <div><input required class="w20" value={this.state.applicant1.drivers_licence_expiry_day || ''} onChange={(e) =>this.handleInputChange(e,'1','drivers_licence_expiry_day')}  type="text" name="" defaultValue=""  placeholder="DD"/> / <input value={this.state.applicant1.drivers_licence_expiry_month || ''} onChange={(e) =>this.handleInputChange(e,'1','drivers_licence_expiry_month')} class="w20" type="text" name="" defaultValue=""  placeholder="MM"/> / <input value={this.state.applicant1.drivers_licence_expiry_year || ''} onChange={(e) =>this.handleInputChange(e,'1','drivers_licence_expiry_year')} class="w20" type="text" name="" defaultValue="" placeholder="YYYY" /></div>
                  :
                  <DatePicker future={true} field="licence_expiry" required={true} dateChange={this.dateChange} applicant_number={1} value = {this.state.applicant1.licence_expiry}></DatePicker>

              }
              </td>
              <td>
              {
                  "drivers_licence_expiry_day" in this.state.applicant2 && !("licence_expiry" in this.state.applicant2) && this.props.showButtons == "false" ?
                  <div><input class="w20" value={this.state.applicant2.drivers_licence_expiry_day || ''} onChange={(e) =>this.handleInputChange(e,'2','drivers_licence_expiry_day')}  type="text" name="" defaultValue=""  placeholder="DD"/> / <input value={this.state.applicant2.drivers_licence_expiry_month || ''} onChange={(e) =>this.handleInputChange(e,'2','drivers_licence_expiry_month')} class="w20" type="text" name="" defaultValue=""  placeholder="MM"/> / <input value={this.state.applicant2.drivers_licence_expiry_year || ''} onChange={(e) =>this.handleInputChange(e,'2','drivers_licence_expiry_year')} class="w20" type="text" name="" defaultValue="" placeholder="YYYY" /></div>
                  :
                  <DatePicker future={true} field="licence_expiry" required={false} dateChange={this.dateChange} applicant_number={2} value = {this.state.applicant2.licence_expiry}></DatePicker>

              }
              </td>
            </tr>

            <tr>
              <td>Marital Status</td>
              <td>
                <select defaultValue="" required className="ui dropdown form-dropdown" value = {this.state.applicant1.martial_status || ''} onChange={(e) =>this.handleInputChange(e,"1","martial_status")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Single">Single</option>
                  <option defaultValue = "Married">Married</option>
                  <option defaultValue = "Defacto">Defacto</option>
                </select> 
              </td>
              <td>
                <select defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant2.martial_status || ''} onChange={(e) =>this.handleInputChange(e,"2","martial_status")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Single">Single</option>
                  <option defaultValue = "Married">Married</option>
                  <option defaultValue = "Defacto">Defacto</option>
                </select> 
              </td>
            </tr>

            <tr>
              <td>Maiden name for female applicant</td>
              <td><input tabindex="10"  type="text" value={this.state.applicant1.maiden_name || ''} onChange={(e) =>this.handleInputChange(e,'1','maiden_name')}  name="" defaultValue="" /></td>
              <td><input type="text" value={this.state.applicant2.maiden_name || ''} onChange={(e) =>this.handleInputChange(e,'2','maiden_name')} name="" defaultValue="" /></td>
            </tr>

            <tr>
              <td>Permanent resident</td>
              <td>

                <select defaultValue="" required className="ui dropdown form-dropdown" value = {this.state.applicant1.resident_status || ''} onChange={(e) =>this.handleInputChange(e,"1","resident_status")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Yes">Yes</option>
                  <option defaultValue = "No">No</option>
                </select>
              </td>
              <td>
                <select defaultValue="" className="ui dropdown form-dropdown" value = {this.state.applicant2.resident_status || ''} onChange={(e) =>this.handleInputChange(e,"2","resident_status")}>
                  <option defaultValue = ""></option>
                  <option defaultValue = "Yes">Yes</option>
                  <option defaultValue = "No">No</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>No. of dependant children</td>
              <td><input tabindex="11"  max="20" required type="text" value={this.state.applicant1.dependant_children || ''} onChange={(e) =>this.handleInputChange(e,'1','dependant_children')}  name="" defaultValue="" /></td>
              <td><input max="20" type="text" value={this.state.applicant2.dependant_children || ''} onChange={(e) =>this.handleInputChange(e,'2','dependant_children')} name="" defaultValue="" /></td>
            </tr>
            {this.renderDependentChildrenInputs()}
          </tbody>
        </table>
        {this.renderButtons()}          
          </form>
        </div>
        )

    }
}

const mapStateToProps = (state) =>{
  return {data:state.data}
}

export default connect(mapStateToProps,{updateData})(General_Details)