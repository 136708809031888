import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom'
import {Provider} from 'react-redux'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {createStore,applyMiddleware,compose} from 'redux'
import reduxThunk from 'redux-thunk'


import App from './components/App';
import reducers from './reducers';

import history from './history'

Sentry.init({
    dsn: "https://d04e17a7b6b347fc80e62ce9c116f095@o558933.ingest.sentry.io/5722234",
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });


  

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));


ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App></App>
        </Router>
    </Provider>

  ,document.querySelector("#root"))
