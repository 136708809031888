import React, { Component } from 'react'
import { connect } from 'react-redux'

import server from '../../../api/server'
import BankStatement from './BankStatement'

export class Documents extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null
        }
    }

    componentDidMount() {
        if(this.state.data == null){

            this.fetchData()
        }
      }
    
      fetchData = async () =>{
        if (this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined || this.props.bankstatements == null) {
            return;
        }
        var response = await server.post("/bankstatements/data", { id: this.props.user.token, application_id:this.props.bankstatements.application_id ,applicant_id:this.props.applicant_id })
        if(response.data && response.data.success == true){
            this.setState({
                data:response.data.htmlArray
            })
        }
      }

    renderFiles = () => {
        var files = []
        if (this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined) {
            return;
        }


        // if(this.state.data != null){
        //         // Bank Statements.
        //         var statements = this.props.bankstatements.applicants[this.props.applicant_id].statements;

        //         Object.keys(statements).forEach((key,index) =>{
        //             var data = null;

        //             if(this.state.data != null){
        //                 var statementData = this.state.data.find(x => x.fileID == key);
        //                 if(statementData != undefined){
        //                     data = statementData.html;
        //                 }
        //             }
        //             files.push(
        
        //                 <div class="card">
        //                     <div class="card-header">
        //                         <div class="card-title collapsed" data-toggle="collapse" data-target={`#${this.props.applicant_id}_bankstatements_${index}`}>
        //                             {statements[key].name} <span class="label label-primary label-inline font-weight-bolder ml-2">Bank Statement</span>
        //                         </div>
        //                     </div>
        //                     <div id={`${this.props.applicant_id}_bankstatements_${index}`} class="collapse" data-parent={`#${this.props.applicant_id}_bankstatements_${index}`}>
        //                         <div class="card-body">
        //                             <BankStatement name={statements[key].name} statements={statements[key].statements} html={data} id={key} applicant_id={this.props.applicant_id}/>
        //                         </div>
        //                     </div>
        //                 </div>
        //             )
        //         })
        // }


        Object.keys(this.props.documents.applicants[this.props.applicant_id].requested_documents).forEach((key, index) => {
            var file_objects = []
            if (this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.length > 0) {
                this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.forEach((file, index) => {

                    file_objects.push(
                        // <div>
                        //     <img alt="" class="max-h-65px" src={process.env.PUBLIC_URL + "/metronic/assets/media/svg/files/pdf.svg"}/>
                        //     <span>{file.file_name}</span>
                        // </div>

                        <div class=" col-lg-3 d-flex flex-column align-items-center cursor-pointer mb-5" onClick={async () => {
                            var response = await server.post("/docs/download", { id: this.props.user.token, path: file.path })
                            if (response.data) {
                                window.open(response.data)
                            }
                        }}>
                            <img alt="" class="max-h-65px" src={process.env.PUBLIC_URL + "/metronic/assets/media/svg/files/doc.svg"} />
                            <span className='text-dark-75 font-weight-bold mt-5 font-size-lg' style={{width:"150px",textAlign:"center"}}>{file.file_name}</span>
                        </div>
                    )
                    
                })
            } else {
                file_objects.push(
                    <div class="col-lg-12 d-flex flex-column align-items-center">
                        <span>Your client is yet to upload any documents for this category.</span>
                    </div>
                )
            }

            // if(this.props.documents.applicants[this.props.applicant_id].requested_documents[key].label == 'Bank Statement Collection'){
            //     return
            // }
            files.push(

                <div class="card">
                    <div class="card-header">
                        <div class="card-title collapsed" data-toggle="collapse" data-target={`#${this.props.applicant_id}_${index}`}>
                            {this.props.documents.applicants[this.props.applicant_id].requested_documents[key].label} <span className={`${this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.length == 0 ? "text-danger" : "text-success"}`}>({this.props.documents.applicants[this.props.applicant_id].requested_documents[key].files.length})</span>
                        </div>
                    </div>
                    <div id={`${this.props.applicant_id}_${index}`} class="collapse" data-parent={`#${this.props.applicant_id}_${index}`}>
                        <div class="card-body">
                            <div className='row'>
                                {file_objects}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })





        return files;
    }


    renderButton = () => {
        if (this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined) {
            return;
        } else {

        }
    }


    render() {
        if (this.props.active_application.application.applicants[this.props.applicant_id] == undefined) {
            return null;
        } else {
            return (
                <div>
                    <div class="h2">{`${this.props.active_application.application.applicants[this.props.applicant_id].name}`} <small class="text-muted">Files</small></div>

                    {(this.props.documents.applicants[this.props.applicant_id].requested_documents == undefined) ?
                        <div>
                            <div>You have not requested any documents for this applicant.</div>

                        </div>
                        :
                        <div>
                            <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                                {this.renderFiles()}
                            </div>

                            <button className='btn btn-info mt-5 mb-5' onClick={async () => {
                                var response = await server.post("/docs/all", { id: this.props.user.token, path: `${this.props.documents.id}/${this.props.applicant_id}` }, { responseType: "blob" });
                                console.log(response.data)
                                let blob = new Blob([response.data], {
                                    type: "application/zip"
                                    //               OR
                                    // type:"application/zip, application/octet-stream"
                                });
                                let objectUrl = URL.createObjectURL(blob);
                                let link = document.createElement('a');
                                link.href = objectUrl;
                                link.download = `${this.props.active_application.application.applicants[this.props.applicant_id].name}.zip`;
                                link.click();

                            }}>Download Applicant Files</button>
                            <div class="separator separator-solid mb-5"/>
                        </div>
                    }


                </div>


            )
        }

    }
}

const mapStateToProps = (state) => ({
    documents: state.admin.active_application.documents,
    bankstatements:state.admin.active_application.bankstatements,
    user: state.user,
    active_application: state.admin.active_application,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)