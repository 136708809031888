import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addClient } from '../../actions'

import $ from "jquery";
import 'bootstrap'


export class AddClientModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             name:"",
             email:"",
             mobile:""
        }
    }

    formSubmit = async (e) =>{
        e.preventDefault()
        this.props.addClient(this.state)
        this.setState({
            name:"",
            email:"",
            mobile:""
       })
        $("#AddClientModal").modal("hide")
        $('.modal-backdrop').remove()
    }
    
    render() {
        return (
            <div>
            <div class="modal fade" id="AddClientModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog " role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Client</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i aria-hidden="true" class="ki ki-close"></i>
                            </button>
                        </div>
                        <form onSubmit={(e) =>{this.formSubmit(e)}}>
                        <div class="modal-body">
                        <div>
                    <div class="form-group">
                        <label>Client Name<span class="text-danger">*</span></label>
                        <input required value={this.state.name} onChange={(e) =>{this.setState({name:e.target.value})}} type="text" class="form-control" placeholder="Client Name" />
                        
                    </div>

                    <div class="form-group">
                        <label>Client Mobile<span class="text-danger">*</span></label>
                        <input required value={this.state.mobile} onChange={(e) =>{this.setState({mobile:e.target.value})}} type="text" class="form-control" placeholder="Client Mobile" />
                    </div>

                    <div class="form-group">
                        <label>Client Email<span class="text-danger">*</span></label>
                        <input value={this.state.email} onChange={(e) =>{this.setState({email:e.target.value})}} type="text" class="form-control" placeholder="Client Email" />
                    </div>
                </div>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary font-weight-bold">Add Client</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user:state.user
})

const mapDispatchToProps = {
    addClient
}

export default connect(mapStateToProps, mapDispatchToProps)(AddClientModal)
