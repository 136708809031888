import React, { Component } from 'react'
import { connect } from 'react-redux'
import DashboardContainer from './DashboardContainer'
import SubHeader from './SubHeader'
import DocumentTypeModal from "./DocumentTypeModal"

import {setOrganisation} from "../../actions"
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import server from '../../api/server';

const SortableItem = SortableElement(({file,index,click}) => 

    <tr>
        <td><strong style={{cursor:"grab"}}> {file.label}</strong></td>
        <td>{file.description}</td>
        <td>{file.min_documents}</td>
        <td>
            <button data-toggle="modal" data-target="#DocumentTypeModal" className="btn btn-sm btn-primary" onClick={() =>{
                click()
                // this.setState({index:index,active_document:file,edit:true})
            }}>Edit</button>
        </td>
    </tr>

);

const SortableList = SortableContainer(({items,click}) => {
  return (
    <tbody>
      {items.map((value, index) => (
        <SortableItem click={(test) =>{
            click(value,index)
        }} key={`item-${value}`} index={index} file={value} />
      ))}
    </tbody>
  );
});


export class Docs_Settings extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
        test:['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
        test1:0,
         active_document:{
            label: "",
            min_documents: 1,
            description: ""
         },
         edit:false,
         index:0
      }

      this.onDragEnd = this.onDragEnd.bind(this);

    }

    componentDidMount() {
        // this.setState({test:this.props.organisation.file_upload_settings.files})
    }



    renderDocumentTypes = () =>{
        var documents = []

        this.state.test.forEach((file,index) => {
            documents.push(

                            <tr>
                            <td><strong>{file.label}</strong></td>
                            <td>{file.description}</td>
                            <td>{file.min_documents}</td>
                            <td>
                                <button data-toggle="modal" data-target="#DocumentTypeModal" className="btn btn-sm btn-primary" onClick={() =>{
                                    this.setState({index:index,active_document:file,edit:true})
                                }}>Edit</button>
                            </td>
                        </tr>
                        )

            

        })


        return documents;
    }


    componentDidUpdate(prevProps, prevState) {
        console.log("UPDATED")
    }

    onDragEnd = e => {
        console.log('DRAG END');
        if (!e.destination) {
          return;
        }
        const test = [...this.state.test]
        const sorted = this.reorder(test, e.source.index, e.destination.index);
        console.log(sorted);
        this.setState({
            test: sorted
        });
      };
    
      reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
            this.setState({test1:this.state.test1+1})
        return result;
      };

      onSortEnd = async ({oldIndex, newIndex}) => {
          console.log("onsortend",oldIndex,newIndex)
          var doc_settings = [...this.props.organisation.file_upload_settings.files]
          var result = this.reorder(doc_settings, oldIndex, newIndex);
          var organisation = {...this.props.organisation};
          organisation.file_upload_settings.files = result;
          var response = await server.post("/doctype/edit",{
              organisation_id:this.props.organisation.id,
              organisation:organisation
          })
        this.props.setOrganisation(organisation)
        console.log(result)

      };
      
  render() {
    return (
      <DashboardContainer>
          <DocumentTypeModal index={this.state.index} edit={this.state.edit} active_document={this.state.active_document} />
          <SubHeader name="Document Collection Settings"></SubHeader>
          <div className="d-flex flex-column-fluid">

            <div className="container">
                <div className="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 py-5">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label font-weight-bolder text-dark">Document Types</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">Add or remove frequently requested document types</span>
                        </h3>
                        <div class="card-toolbar">

                            <button onClick={() =>{
                                this.setState({
                                    active_document:{
                                        label: "",
                                        min_documents: 1,
                                        description: ""
                                    },
                                    edit:false
                                })
                            }} data-toggle="modal" data-target="#DocumentTypeModal"  class="btn btn-info font-weight-bolder font-size-sm mr-3">New Document Type</button>

                        </div>
                    </div>


                    <div className="card-body pt-0 pb-3">
                        <div className="tab-content">
                            <div className="table-responsive">

                                        
                                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                            <thead>
                                                <tr class="text-left text-uppercase">
                                                    <th style={{cursor:"pointer"}} onClick={() =>{this.sort("name")}}>Document Label</th>
                                                    <th >Description</th>
                                                    <th >Minimum Required Documents</th>
                                                    <th >Edit</th>
                                                </tr>
                                            </thead>


                                            <SortableList click={(value,index) =>{
                                                this.setState({index:index,active_document:value,edit:true})
                                            }} items={this.props.organisation.file_upload_settings.files} onSortEnd={this.onSortEnd} />


                                        </table>



                            </div>
                        </div>
                    </div>




                </div>
            </div>

</div>
      </DashboardContainer>
    )
  }
}

const mapStateToProps = (state) => ({
    organisation:state.organisation
})

const mapDispatchToProps = {
    setOrganisation
}

export default connect(mapStateToProps, mapDispatchToProps)(Docs_Settings)