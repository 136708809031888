import React, { Component } from 'react'
import { connect } from 'react-redux'

import {createApplication} from "../../actions"
import $ from "jquery";
import 'bootstrap'

export class ApplicationAddModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             name:"",
             applicants:[
                 {
                     name:"",
                     mobile:"",
                     email:""
                 }
             ]
        }
    }

    formSubmit = (e) =>{
        e.preventDefault();

        this.props.createApplication({name:this.state.name,applicants:this.state.applicants})
        // $("#ClientAddModal").modal("hide")
        this.formClose()

        // this.setState({
        //     name:"",
        // email:"",
        // factfind:false,
        // id:false,
        // document:false})
    }

    formClose = (e) =>{
        this.setState( {
            name:"",
            applicants:[
                {
                    name:"",
                    mobile:"",
                    email:""
                }
            ]
       })
       $("#ApplicationAddModal").modal("hide")
       $('.modal-backdrop').remove()
    }

    applicantOnChange =(field,value,key) =>{
        const applicants = [...this.state.applicants];

        applicants[key][field] = value;

        this.setState({applicants:applicants});
    }

    addApplicant = () =>{
        const applicants = [...this.state.applicants];
        applicants.push(                 {
            name:"",
            mobile:"",
            email:""
        })

        this.setState({applicants:applicants})
    }

    deleteApplicant = () =>{
        const applicants = [...this.state.applicants];

        if(applicants.length >1){
            const length = applicants.length-1;
            applicants.pop()
            this.setState({applicants:applicants})
        }

    }

    renderApplicants = () =>{
        const applicants = [];
        this.state.applicants.forEach((applicant,key) =>{
            applicants.push(
                <div>
                    <div class="form-group">
                        <label>Client Name<span class="text-danger">*</span></label>
                        <input required value={applicant.name} onChange={(e) =>{this.applicantOnChange("name",e.target.value,key)}} type="text" class="form-control" placeholder="Client Name" />
                        
                    </div>

                    <div class="form-group">
                        <label>Client Mobile<span class="text-danger">*</span></label>
                        <input required value={applicant.mobile} onChange={(e) =>{this.applicantOnChange("mobile",e.target.value,key)}} type="text" class="form-control" placeholder="Client Mobile" />
                    </div>

                    <div class="form-group">
                        <label>Client Email<span class="text-danger">*</span></label>
                        <input value={applicant.email} onChange={(e) =>{this.applicantOnChange("email",e.target.value,key)}} type="text" class="form-control" placeholder="Client Email" />
                    </div>
                    <div class="separator separator-dashed my-8"></div>
                </div>


            )
        })
        return applicants;
    }
    
    render() {
        return (
            <div>
                <div class="modal fade" id="ApplicationAddModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog " role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">New Application</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <i aria-hidden="true" class="ki ki-close"></i>
                                </button>
                            </div>
                            <form onSubmit={(e) =>{this.formSubmit(e)}}>
                            <div class="modal-body">

                                    <div class="form-group">
                                        <label>Application Name<span class="text-danger">*</span></label>
                                        <input required value={this.state.name} onChange={(e) =>{this.setState({name:e.target.value})}} type="text" class="form-control" placeholder="Application Name" />
                                        <span class="form-text text-muted">A descriptive name for the application.</span>
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    {this.renderApplicants()}

                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-danger mr-2 btn-small" type="button" onClick={(e) =>{this.deleteApplicant()}}>Delete Client</button>
                                        <button className="btn btn-info btn-small" type="button" onClick={(e) =>{this.addApplicant()}}>Add Client</button>
                                    </div>

 
                            </div>
                            <div class="modal-footer">
                                <button type="button" onClick={(e) =>{this.formClose()}} class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary font-weight-bold">Create Application</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    createApplication
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationAddModal)
