import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import Header from './Header'
import Identification from './Identification'

import { fetchForm } from '../../actions'


import server from "../../api/server"
import { HeaderMenu } from './HeaderMenu'

export class NewDash extends Component {

    constructor(props) {
        super(props)

        this.state = {
            menu: "identification",
            name: ""
        }
    }

    componentDidMount() {
        this.props.fetchForm(this.props.match.params.id)
        this.fetchName()
    }

    fetchName = async () => {
        const response = await server.post("/name", { id: this.props.match.params.id });
        if (response.data != false) {
            console.log(response.data)
            this.setState({ name: response.data })
        } else {
            this.setState({ name: " " })
        }
    }


    renderHelmet = () => {
        return (
            <Helmet>

                <meta charset="utf-8" />
                <title>Dashboard | LoanGateway</title>
                <meta name="description" content="Updates and statistics" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="canonical" href="https://keenthemes.com/metronic" />


                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700" />


                <link href={process.env.PUBLIC_URL + "/dashboard/plugins/custom/fullcalendar/fullcalendar.bundle.css"} rel="stylesheet" type="text/css" />


                <link href={process.env.PUBLIC_URL + "/dashboard/global/plugins.bundle.css"} rel="stylesheet" type="text/css" />
                <link href={process.env.PUBLIC_URL + "/dashboard/plugins/custom/prismjs/prismjs.bundle.css"} rel="stylesheet" type="text/css" />
                <link href={process.env.PUBLIC_URL + "/dashboard/css/style.bundle.css"} rel="stylesheet" type="text/css" />


                <link rel="shortcut icon" href={process.env.PUBLIC_URL + "/dashboard/media/logos/favicon.ico"} />
            </Helmet>
        )
    }

    renderContent = () => {
        if (this.state.menu == "dashboard") {
            return (
                <div className="row mt-lg-3">

                    <div className="col-xl-8">
                        <div className="row">

                            <div className="col-xl-6">
                                <div class="card card-custom gutter-b card-stretch">

                                    <div class="card-body">
                                        <div class="d-flex flex-wrap align-items-center py-1">

                                            <div class="symbol symbol-80 symbol-light-danger mr-5">
                                                <span class="symbol-label">
                                                    <img src={process.env.PUBLIC_URL + "/dashboard/media/svg/misc/008-infography.svg"} class="h-50 align-self-center" alt="" />
                                                </span>
                                            </div>

                                            <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                                <a href="#" class="text-dark font-weight-bolder text-hover-primary font-size-h5">Fact Find
</a>
                                                <span class="text-muted font-weight-bold font-size-lg">A short description</span>
                                            </div>

                                            <div class="d-flex flex-column w-100 mt-5">
                                                <span class="text-dark mr-2 font-size-lg font-weight-bolder pb-3">Complete!</span>
                                                <div class="progress progress-xs w-100">
                                                    <div class="progress-bar bg-success" role="progressbar" style={{ width: "100%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div class="card card-custom gutter-b card-stretch">

                                    <div class="card-body">
                                        <div class="d-flex flex-wrap align-items-center py-1">

                                            <div class="symbol symbol-80 symbol-light-success mr-5">
                                                <span class="symbol-label">
                                                    <img src={process.env.PUBLIC_URL + "/dashboard/media/svg/misc/013-rgb.svg"} class="h-50 align-self-center" alt="" />
                                                </span>
                                            </div>

                                            <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                                <a href="#" class="text-dark font-weight-bolder text-hover-primary font-size-h5">Identification
</a>
                                                <span class="text-muted font-weight-bold font-size-lg">A short description</span>
                                            </div>

                                            <div class="d-flex flex-column w-100 mt-5">
                                                <span class="text-dark mr-2 font-size-lg font-weight-bolder pb-3">{this.props.VOI.progress == true ? "Complete!" : "Progress"}</span>
                                                <div class="progress progress-xs w-100">
                                                    <div class={`progress-bar bg-${this.props.VOI.progress == true ? "success" : "danger"}`} role="progressbar" style={{ width: `${this.props.VOI.progress == true ? "100%" : "0%"}` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div class="card card-custom gutter-b card-stretch">

                                    <div class="card-body">
                                        <div class="d-flex flex-wrap align-items-center py-1">

                                            <div class="symbol symbol-80 symbol-light-info mr-5">
                                                <span class="symbol-label">
                                                    <img src={process.env.PUBLIC_URL + "/dashboard/media/svg/misc/011-swarm.svg"} class="h-50 align-self-center" alt="" />
                                                </span>
                                            </div>

                                            <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                                <a href="#" class="text-dark font-weight-bolder text-hover-primary font-size-h5">Document Upload
</a>
                                                <span class="text-muted font-weight-bold font-size-lg">A short description</span>
                                            </div>

                                            <div class="d-flex flex-column w-100 mt-5">
                                                <span class="text-dark mr-2 font-size-lg font-weight-bolder pb-3">Progress</span>
                                                <div class="progress progress-xs w-100">
                                                    <div class="progress-bar bg-danger" role="progressbar" style={{ width: "65%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>




                    <div class="col-xl-4">

                        <div class="card card-custom gutter-b card-stretch bg-info">
                            <div class="card-body d-flex p-0">
                                {/* "background-position: right bottom; background-size: 55% auto; background-image: url(/metronic/theme/html/demo9/dist/assets/media/svg/humans/custom-6.svg)" */}
                                <div class="flex-grow-1 bg-info p-12 pb-40 card-rounded flex-grow-1 bgi-no-repeat dashboard-info-bg">
                                    <h3 class="text-inverse-info pb-5 font-weight-bolder">Welcome to your LoanGateway.</h3>
                                    <p class="text-inverse-info pb-5 font-size-h6">Ryan please write some text for here about what this is and what info we require ect etc big dog big dog jobs jobs jobs.

                        </p>
                                    <a href="#" class="btn btn-success font-weight-bold py-2 px-6">Join Now</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        } else if (this.state.menu == "identification") {
            return (
                "Test"
            )
        }
    }

    changeMenu = (menu) => {
        this.setState({ menu: menu })
        console.log(menu)
    }

    render() {
        return (
            <div>
                {this.renderHelmet()}
                <body id="kt_body" class="header-fixed subheader-enabled page-loading">                    {/* <!--begin::Main--> */}


                    {/* <!--begin::Main--> */}
                    <div class="d-flex flex-column flex-root">

                        {/* <!--begin::Page--> */}
                        <div class="d-flex flex-row flex-column-fluid page">

                            {/* <!--begin::Wrapper--> */}
                            <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

                                {/* <!--[html-partial:include:{"file":"partials/_header-mobile.html"}]/--> */}

                                {/* <!--[html-partial:include:{"file":"partials/_header.html"}]/--> */}
                                <Header/>

                                {/* <!--[html-partial:include:{"file":"partials/_header-menu.html"}]/--> */}
                                <HeaderMenu/>

                                {/* <!--begin::Container--> */}

                                
{/* <!--begin::Main--> */}
		<div class="d-flex flex-column flex-root">

			{/* <!--begin::Page--> */}
			<div class="d-flex flex-row flex-column-fluid page">

				{/* <!--begin::Wrapper--> */}
				<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

					{/* <!--[html-partial:include:{"file":"partials/_header-mobile.html"}]/--> */}

					{/* <!--[html-partial:include:{"file":"partials/_header.html"}]/--> */}

					{/* <!--[html-partial:include:{"file":"partials/_header-menu.html"}]/--> */}

					{/* <!--begin::Container--> */}
					<div class="d-flex flex-row flex-column-fluid container">

						{/* <!--begin::Content Wrapper--> */}
						<div class="main d-flex flex-column flex-row-fluid">

							{/* <!--[html-partial:include:{"file":"partials/_subheader/subheader-v1.html"}]/--> */}
							<div class="content flex-column-fluid" id="kt_content">

								{/* <!--[html-partial:begin:{"id":"demo8/dist/inc/view/demos/pages/index","page":"index"}]/--> */}

								{/* <!--[html-partial:begin:{"id":"demo1/dist/inc/view/partials/content/dashboards/demo8","page":"index"}]/--> */}

								{/* <!--begin::Dashboard--> */}

								{/* <!--begin::Row--> */}

								{/* <!-- Content --> */}


								{/* <!--end::Row--> */}

								{/* <!--end::Dashboard--> */}

								{/* <!--[html-partial:end:{"id":"demo1/dist/inc/view/partials/content/dashboards/demo8","page":"index"}]/--> */}

								{/* <!--[html-partial:end:{"id":"demo8/dist/inc/view/demos/pages/index","page":"index"}]/--> */}
							</div>

							{/* <!--end::Content--> */}
						</div>

						{/* <!--begin::Content Wrapper--> */}
					</div>

					{/* <!--end::Container--> */}

					{/* <!--[html-partial:include:{"file":"partials/_footer.html"}]/--> */}
				</div>

				{/* <!--end::Wrapper--> */}
			</div>

			{/* <!--end::Page--> */}
		</div>

		{/* <!--end::Main--> */}


                                {/* <!--end::Container--> */}

                                {/* <!--[html-partial:include:{"file":"partials/_footer.html"}]/--> */}
                            </div>

                            {/* <!--end::Wrapper--> */}
                        </div>

                        {/* <!--end::Page--> */}
                    </div>

                </body>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    VOI: state.VOI,
    requested_info: state.requested_info
})

const mapDispatchToProps = {
    fetchForm
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDash)
