import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { addClient } from '../../actions'

import $ from "jquery";
import 'bootstrap'
import server from '../../../api/server';
import {fetchApplication} from '../../../actions'



export class EditDocCollectionModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            disabled:false,
             applicants: {

            }
        }
    }

    formSubmit = async (e) =>{
        e.preventDefault()
        this.props.addClient(this.state)
        this.setState({
            name:"",
            email:"",
            mobile:""
       })
        $("#EditDocCollectionModal").modal("hide")
        $('.modal-backdrop').remove()
    }

    componentDidMount() {
        this.setApplicants()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.documents != null && Object.keys(this.props.documents.applicants).length != Object.keys(this.state.applicants).length) {
            this.setApplicants()
        }
    }

    setApplicants = () => {
        var applicants = { ...this.state.applicants };
        if(this.props.documents == null){
            return null;
        }
        Object.keys(this.props.documents.applicants).forEach(key => {
            console.log(key,'EDIT MDOAL')
            var files = []

            this.props.organisation.file_upload_settings.files.forEach((file, index) => {
                var requested = false;
                var file_key = file.label.toLowerCase().replace(/ /g,"_")
                if(this.props.documents.applicants[key].requested_documents != undefined && this.props.documents.applicants[key].requested_documents[file_key] != undefined) requested = true;
                files.push(
                    {
                        label: file.label,
                        index: index,
                        description:file.description,
                        min_documents:file.min_documents,
                        requested: requested
                    }
                )
            })
            applicants[key] = {
                name: this.props.documents.applicants[key].name,
                files: [...files]
            }
        })
        this.setState({ applicants })
    }


    renderDocRequest = () => {
        var applicants = []


        Object.keys(this.state.applicants).forEach(key => {
            var files = []
            this.state.applicants[key].files.forEach(file => {
                files.push(
                    <div className='col-4 mb-3'>
                        <div className='checkbox-list'>
                            <label class="checkbox">
                                <input value={file.requested} type="checkbox" name={`filebox_${key}_${file.index}`} checked={file.requested == true ? "checked" : ""} onChange={(e) => {
                                    var applicants = { ...this.state.applicants };
                                    console.log(applicants)
                                    applicants[key].files[file.index].requested = !file.requested
                                    this.setState({ applicants: applicants })
                                }} />
                                <span></span>
                                {file.label}
                            </label>
                        </div>

                    </div>
                )
            })
            applicants.push(
                <div className='mb-5'>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <h3 className=''>{this.state.applicants[key].name}</h3>
                        <button onClick={() =>{
                            var applicants = { ...this.state.applicants };
                            applicants[key].files.forEach(file => {
                                file.requested = true;
                            })
                            this.setState({ applicants: applicants })
                        }} className='btn btn-sm btn-danger ml-auto'>Select All</button>
                    </div>
                    <form>
                        <div className='row'>
                            {files}
                        </div>
                    </form>

                        <div class="separator separator-solid mb-5"/>
                </div>
            )
        })
        console.log(applicants, 'APPLICANTS')
        return applicants;
    }

    
    render() {
        return (
            <div>
            <div class="modal fade" id="EditDocCollectionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Edit Document Collection</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <i aria-hidden="true" class="ki ki-close"></i>
                            </button>
                        </div>

                        <div class="modal-body">

                            {this.renderDocRequest()}




                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                            <button disabled={this.state.disabled} type="submit" class="btn btn-primary font-weight-bold" onClick={async () =>{
                                this.setState({disabled:true})
                                var response = await server.post("/docrequest/edit",{organisation_id:this.props.organisation.id,state:this.state,application_id:this.props.active_application.application.id});
                                if(response.data){
                                    this.props.fetchApplication(this.props.active_application.application.id)
                                }
                                $("#EditDocCollectionModal").modal("hide")
                                $('.modal-backdrop').remove()
                                this.setState({disabled:false})
                            }}>Edit Document Collection</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user:state.user,
    documents: state.admin.active_application.documents,
    active_application: state.admin.active_application,
    organisation: state.organisation
})

const mapDispatchToProps = {
    // addClient
    fetchApplication
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDocCollectionModal)
