import React, { Component } from 'react'
import { connect } from 'react-redux'

import server from '../../../api/server';
import {fetchApplication} from '../../../actions'

export class RequestDocCollection extends Component {

    constructor(props) {
        super(props)

        this.state = {
            test: true,
            applicants: {

            }
        }
    }

    componentDidMount() {
        this.setApplicants()
    }

    setApplicants = () => {
        var applicants = { ...this.state.applicants };
        Object.keys(this.props.active_application.application.applicants).forEach(key => {
            var files = []
            // files.push(
            //     {
            //         label:"Bank Statement Collection",
            //         index:0,
            //         requested:false
            //     }
            // )
            this.props.organisation.file_upload_settings.files.forEach((file, index) => {
                files.push(
                    {
                        label: file.label,
                        description:file.description,
                        min_documents:file.min_documents,
                        index: index,
                        requested: false
                    }
                )
            })
            applicants[key] = {
                name: this.props.active_application.application.applicants[key].name,
                files: [...files]
            }
        })
        this.setState({ applicants })
    }

    componentDidUpdate(prevProps, prevState) {
        if (Object.keys(this.props.active_application.application.applicants).length != Object.keys(this.state.applicants).length) {
            this.setApplicants()
        }
    }


    createDocRequest = async () =>{

        var response = await server.post("/docrequest/create",{organisation_id:this.props.organisation.id,state:this.state,application_id:this.props.active_application.application.id});

        if(response.data){
            this.props.fetchApplication(this.props.active_application.application.id)

        }

    }


    renderDocRequest = () => {
        var applicants = []


        Object.keys(this.state.applicants).forEach(key => {
            var files = []
            this.state.applicants[key].files.forEach(file => {

                files.push(
                    <div className='col-4 mb-3'>
                        <div className='checkbox-list'>
                            <label class="checkbox">
                                <input value={file.requested} type="checkbox" name={`filebox_${key}_${file.index}`} checked={file.requested == true ? "checked" : ""} onChange={(e) => {
                                    var applicants = { ...this.state.applicants };
                                    console.log(applicants)
                                    applicants[key].files[file.index].requested = !file.requested
                                    this.setState({ applicants: applicants })
                                }} />
                                <span></span>
                                {file.label}
                            </label>
                        </div>

                    </div>
                )
            })
            applicants.push(
                <div className='mb-5'>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <h3 className=''>{this.state.applicants[key].name}</h3>
                        <button onClick={() =>{
                            var applicants = { ...this.state.applicants };
                            applicants[key].files.forEach(file => {
                                file.requested = true;
                            })
                            this.setState({ applicants: applicants })
                        }} className='btn btn-sm btn-danger ml-auto'>Select All</button>
                    </div>
                    <form>
                        <div className='row'>
                            {files}
                        </div>
                    </form>

                        <div class="separator separator-solid mb-5"/>
                </div>
            )
        })
        console.log(applicants, 'APPLICANTS')
        return applicants;
    }

    render() {
        return (

            <div className="row ">
                <div className="col-lg-12">
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className='card-title'>
                                <h3 className="card-label font-weight-bolder text-dark">Request Document Collection</h3>

                            </div>
                            {/* <div className='card-toolbar'>
                                <button className='btn btn-small btn-info'>Add Document Type</button>
                            </div> */}
                            {/* <span class="text-muted font-weight-bold font-size-sm mt-1">Document Collection</span> */}
                        </div>


                        <div className='card-body'>
                            <div className='mb-5'>
                                {this.renderDocRequest()}
                            </div>
                            <div>
                                <button onClick={() =>{
                                    this.createDocRequest()
                                }} className='btn btn-info float-right'>Request Document Collection</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    documents: state.admin.active_application.documents,
    active_application: state.admin.active_application,
    organisation: state.organisation
})

const mapDispatchToProps = {
    fetchApplication
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestDocCollection)