import React, { Component } from 'react'
import { connect } from 'react-redux'
import Nav from '../Nav'
import { Helmet } from 'react-helmet'
import server from '../../api/server'
import { fetchDocCollection,setID } from '../../actions'
import FileUploader from ".//FileUploader"
export class DocumentCollection extends Component {

    componentDidMount() {
        // this.fetchDocumentCollections()
        this.props.fetchDocCollection(this.props.match.params.id)
        this.props.setID(this.props.match.params.id)
    }

    constructor(props) {
      super(props)
    
      this.state = {
         active_applicant:null
      }
    }


    renderApplicants = () =>{
        const applicants = []
        if(this.props.document_collection.data != null){
            Object.keys(this.props.document_collection.data.applicants).forEach(key =>{
                if(this.props.document_collection.data.applicants[key].requested_documents != undefined){
                    applicants.push(
                        <div className="dashboard-module file-upload-item">
                                <h2>{this.props.document_collection.data.applicants[key].name}</h2>
                                <p style={{ "marginTop": "7px", marginBottom: "14px" }}>Please click the Start Document Collection button below to upload documents required for your application. </p>
                                <button onClick={() =>{
                                    this.setState({active_applicant:key})
                                }} class="ui button dashboard-module-button" type="button">Start Document Collection</button>
                        </div>
                    )
                }
            })
        }
        return applicants;
    }

    renderContent = () =>{
        if(this.state.active_applicant == null){
            return this.renderApplicants()
        }else{
            if(this.props.document_collection.data.applicants[this.state.active_applicant].requested_documents == undefined){
                return(null)
            }else{
                var requested_files = []
                Object.keys(this.props.document_collection.data.applicants[this.state.active_applicant].requested_documents).forEach(key =>{
                    requested_files.push(<FileUploader data={this.props.document_collection.data.applicants[this.state.active_applicant].requested_documents[key]}  params = {this.props.match.params} applicant_id = {this.state.active_applicant}/>)
                })
                console.log(requested_files[0].props.data.index)
                var sorted = requested_files.sort((a,b) =>(a.props.data.index > b.props.data.index) ? 1: -1);

                return sorted
            }
        }
    }


    render() {
        return (
            <div>
                <Helmet>
                    <title>Fact Find - Mortgage Choice Sydney CBD</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" integrity="sha512-8bHTC73gkZ7rZ7vpqUQThUDhqcNFyYi2xgDgPDHc+GXVGHXq+xPjynxIopALmOPqzo9JZj0k6OqqewdGO3EsrQ==" crossorigin="anonymous" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/components/grid.min.css" integrity="sha512-RtktBL0Hhw9K2e2rGLZGadK8cF6Q0wKxRUkfh4pJszwnarmh3GbqHCiLm3UZFA1dRiFqtPkVrcby0hXWsqoDNA==" crossorigin="anonymous" />
                    <link rel="stylesheet" href={process.env.PUBLIC_URL + `/css/style.css`} />

                </Helmet>
                <Nav showProgress="false"></Nav>

                <div>

                    <section className="main-wrap">
                        <div className="file-upload-row">
                            {/* <FileUploader params = {this.props.match.params} /> */}

                            {
                                this.renderContent()
                            }




                            
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    document_collection:state.document_collection
})

const mapDispatchToProps = {
    fetchDocCollection,
    setID
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCollection)