import React from 'react';
import {BrowserRouter, Route,Switch} from 'react-router-dom'

import history from "../history"

import Form from './Form.js'
import Admin from './Admin.js'
import Login from './newadmin/Login'
import AdminForm from './AdminForm.js';
import Dashboard from './Dashboard.js';
import HomePage from './HomePage.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import  NewAdmin  from './NewAdmin.js';
import  NotFound  from './NotFound.js';
import VOIClientSide from "./voi/VOIClientSide"
import NewDash from "./newdashboard/Dashboard";


// ADMIN
import Applications from "./newadmin/Applications"
import  ClientPage  from './newadmin/ClientPage';
import  VOICompleted  from './voi/VOICompleted';
import FileUpload from './FileUpload';
import DocumentCollection from './fileupload/DocumentCollection';
import Docs_Settings from './newadmin/Docs_Settings';
import Billing from './newadmin/Billing';
import Privacy from './standalones/Privacy';
import Security from './standalones/Security';
import Agents from './newadmin/Agents';
import User from './newadmin/User';
import ClientBankStatements from './ClientBankStatements';

const App = () =>{

  return(
    <div>

      <Switch>
        <Route exact path="/oldadmin" component={Admin}></Route>
        <Route exact path="/admin/applications" component={Applications}></Route>
        <Route exact path="/admin/application/:id" component={ClientPage}></Route>
        <Route exact path="/admin/settings/uploads" component={Docs_Settings}></Route>
        <Route exact path="/admin/settings/billing" component={Billing}></Route>
        <Route exact path="/admin/settings/agents" component={Agents}></Route>
        <Route exact path="/admin/settings/user" component={User}></Route>
        <Route exact path="/admin" component={Applications}></Route>
        <Route exact path="/login" component={Login}></Route>
        <Route exact path="/privacy" component={Privacy}></Route>
        <Route exact path="/security" component={Security}></Route>
        <Route exact path="/factfindform/:id" component={Form}></Route>
        <Route exact path="/identification/:id" component={VOIClientSide}></Route>
        <Route exact path="/upload/:id" component={DocumentCollection}></Route>
        <Route exact path="/bankstatements/:id" component={ClientBankStatements}></Route>
        <Route exact path="/identification/:id/completed" component={VOICompleted}></Route>
        <Route exact path="/adminform/:id" render={(props) => (
          <AdminForm {...props} versions={false}></AdminForm>
        )}></Route>

<Route exact path="/versions/:version_id/:form_id" render={(props) => (
          <AdminForm {...props} versions={true}></AdminForm>
        )}></Route>
        <Route exact path="/dashboard/:id" component={Dashboard}></Route>
        {/* <Route exact path="/newdashboard/:id" component={NewDash}></Route> */}
        <Route path="*"><NotFound></NotFound></Route>
        </Switch>
    </div>

  )
}



export default App;
